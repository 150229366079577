import React from 'react';

import * as Icon from 'react-feather';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

function HideOnScroll(props) {
	const { children, window } = props;
	const trigger = useScrollTrigger({ target: window ? window() : undefined });

	return (
		<Slide appear={false} direction="down" in={!trigger}>
			{children}
		</Slide>
	);
}

HideOnScroll.propTypes = {
	children: PropTypes.element.isRequired,
	window: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	appBar: {
		background: '#146acc'
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	toolbar: {
		minHeight: '48px'
	},
	drawerLinksWrapper: {
		background: '#146acc',
		minHeight: '100%'
	},
	drawerLink: {
		color: '#fff',
		textDecoration: 'none'
	},
	drawerIcon: {
		marginLeft: '5px',
		marginRight: '10px'
	}
}));

export default function Header(props) {
	const classes = useStyles();

	// --- DRAWER
	const [drawerState, setDrawerState] = React.useState({
		open: false
	});

	const toggleDrawer = (open) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setDrawerState({ ...drawerState, open: open });
	};

	const drawerLinks = () => (
		<div
			role="presentation"
			onClick={toggleDrawer(false)}
			onKeyDown={toggleDrawer(false)}
			className={classes.drawerLinksWrapper}
		>
			<List>
				<Link to="/" className={classes.drawerLink}>
					<ListItem button>
						<Icon.Home className={classes.drawerIcon} />
						<Typography variant="subtitle2">
							Home
						</Typography>
					</ListItem>
				</Link>
				<Divider />

				<Link to="/stories" className={classes.drawerLink}>
					<ListItem button>
						<Icon.BookOpen className={classes.drawerIcon} />
						<Typography variant="subtitle2">
							Stories
						</Typography>
					</ListItem>
				</Link>
				<Divider />

				<Link to="/songs" className={classes.drawerLink}>
					<ListItem button>
						<Icon.Music className={classes.drawerIcon} />
						<Typography variant="subtitle2">
							Songs
						</Typography>
					</ListItem>
				</Link>
				<Divider />

				<Link to="/literacy-skills" className={classes.drawerLink}>
					<ListItem button>
						<Icon.Edit2 className={classes.drawerIcon} />
						<Typography variant="subtitle2">
							Rhymes
						</Typography>
					</ListItem>
				</Link>
				<Divider />

				<Link to="/about" className={classes.drawerLink}>
					<ListItem button>
						<Icon.Info className={classes.drawerIcon} />
						<Typography variant="subtitle2">
							About
						</Typography>
					</ListItem>
				</Link>
				<Divider />
			</List>
		</div>
	);

	return (
		<div>
			<React.Fragment>
				<CssBaseline />
				<HideOnScroll {...props}>
					<AppBar className={classes.appBar}>
						<Toolbar variant="dense">
							<IconButton onClick={toggleDrawer(true)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
								<Icon.Menu />
							</IconButton>
							{/* <BackButton /> */}
							<Link to="/" className={classes.drawerLink}>
								<h3>Te Ribana</h3>
							</Link>
						</Toolbar>
					</AppBar>
				</HideOnScroll>
				<Toolbar className={classes.toolbar} />
			</React.Fragment>

			<div>
				<Drawer open={drawerState.open} onClose={toggleDrawer(false)}>
					{drawerLinks()}
				</Drawer>
			</div>
		</div>
	)
}