import React from 'react'
import { Link } from 'react-router-dom'
import useSWR from 'swr';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

import Helmet from 'react-helmet';
import * as Icon from 'react-feather';

export default function StoriesPage() {

	// fetch stories data
	const url = 'https://kiribatifederationaotearoa.co.nz/api/story-categories/all';
	const fetcher = (...args) => fetch(...args).then(res => res.json());
	const { data, error } = useSWR(url, fetcher);

	// error handling
	if (error) return <div className="loading-container">Failed to load</div>;
	if (!data) return <Grid container justify="center" alignItems="center" className="loading-container">
		<CircularProgress disableShrink />
	</Grid>;

	return (
		<div>
			<div className="page-header">
				<Helmet>
					<title>Te Ribana - Stories</title>
				</Helmet>
				<Typography variant="h4">
					<Icon.BookOpen /> Karaki
				</Typography>
				<Typography gutterBottom variant="h6" className="landing-page-sub-title">
					Stories
				</Typography>
				<Typography gutterBottom variant="body2" color="textSecondary">
					Choose an island to read stories from or read all stories from all over Kiribati.
				</Typography>
			</div>

			<Grid container>
				{data.map((i) => {
					return (
						<Grid item key={i.ID} xs={12} sm={12} md={6} lg={4} className="flex-grid-item">
							<Link to={`/story-category/${i.ID}`} className="card-link">
								<Card className="flex-grid-item__card" elevation={3}>
									<CardActionArea className="flex-grid-item__card__action-area">
										<CardMedia
											className="card-media-image"
											image={i.CategoryCoverImage}
											title={i.CategoryCoverImageTitle}
										/>
										<CardContent className="flex-grid-item__card__content-area">
											<Typography variant="h6">
												{i.Category}
											</Typography>
											<Typography variant="body2" color="textSecondary" component="p">
												{i.Description}
											</Typography>
										</CardContent>
									</CardActionArea>
									<CardActions className="card-actions card-actions--bg-red">
										<Button>
											Read stories from {i.Category}
										</Button>
									</CardActions>
								</Card>
							</Link>
						</Grid>
					)
				})}
			</Grid>
		</div>
	)
}
