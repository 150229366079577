import React from 'react'

import useSWR from 'swr';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import * as Icon from 'react-feather';
import Helmet from 'react-helmet';

export default function SongsPage() {
	// fetch songs data
	const url = 'https://kiribatifederationaotearoa.co.nz/api/songs/all';
	const fetcher = (...args) => fetch(...args).then(res => res.json());
	const { data, error } = useSWR(url, fetcher);

	// error handling
	if (error) return <div className="loading-container">Sorry something went wrong, please try again later.</div>;
	if (!data) return <Grid container className="loading-container" justify="center" alignItems="center">
		<CircularProgress disableShrink />
	</Grid>;

	return (
		<div>
			<Helmet>
				<title>Te Ribana - Songs</title>
			</Helmet>

			<div className="page-header">
				<Typography variant="h4">
					<Icon.Music /> Kuna
				</Typography>
				<Typography gutterBottom variant="h6" className="landing-page-sub-title">
					Songs
				</Typography>
				<Typography gutterBottom variant="body1" color="textSecondary">
					Song lyrics and nursery rhymes.
				</Typography>
			</div>

			<Grid container>
				{data.map((i) => {
					return (
						<Grid item key={i.ID} xs={12} sm={12} md={6} lg={4} className="flex-grid-item">
							<Link to={`/song/${i.ID}`} className="card-link">
								<Card className="flex-grid-item__card" elevation={3}>
									<CardActionArea className="flex-grid-item__card__action-area">
										<CardContent className="flex-grid-item__card__content-area">
											<Typography gutterBottom variant="h6">
												{i.Title}
											</Typography>
											<Typography variant="body2" color="textSecondary" component="p">
												{i.Summary}
											</Typography>
										</CardContent>
									</CardActionArea>
									<CardActions className="card-actions card-actions--bg-yellow">
										<Button>
											Play song
										</Button>
									</CardActions>
								</Card>
							</Link>
						</Grid>
					)
				})}
			</Grid>
		</div>
	)
}