import axios from 'axios'
import React, { Component } from 'react'
import Helmet from 'react-helmet';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

class StoryPage extends Component {

	state = {
		story: []
	}

	componentDidMount() {
		const storyID = this.props.match.params.id;

		axios
			.get(`https://kiribatifederationaotearoa.co.nz/api/stories/byID/${storyID}`)
			.then(res => this.setState({ story: res.data }));
	}

	render() {

		return (
			<div>
				<Helmet>
					<title>{`Te Ribana - ${this.state.story.Title}`}</title>
				</Helmet>

				<Grid item xs={12}>
					<Typography variant="h4" className="item-title">
						{this.state.story.Title}
					</Typography>
					<Typography dangerouslySetInnerHTML={{ __html: this.state.story.Content }} className="content-body">
					</Typography>
				</Grid>
			</div>
		)
	}
}

export default StoryPage