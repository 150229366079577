import React from 'react'
import { Link } from 'react-router-dom'
import useSWR from 'swr';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import * as Icon from 'react-feather';
import Helmet from 'react-helmet';

export default function LiteracySkillsPage() {

	// fetch Literacy Skills Categories data
	const url = 'https://kiribatifederationaotearoa.co.nz/api/literacy-skill-categories/all';
	const fetcher = (...args) => fetch(...args).then(res => res.json());
	const { data, error } = useSWR(url, fetcher);

	// error handling
	if (error) return <div className="loading-container">Failed to load</div>;
	if (!data) return <Grid container justify="center" alignItems="center" className="loading-container">
		<CircularProgress disableShrink />
	</Grid>;

	return (
		<div>
			<Helmet>
				<title>Te Ribana - Literacy</title>
			</Helmet>

			<div className="page-header">
				<Typography variant="h4">
					<Icon.Edit2 /> Kamatenano
				</Typography>
				<Typography gutterBottom variant="h6" className="landing-page-sub-title">
					Rhymes
				</Typography>
				<Typography gutterBottom variant="body2" color="textSecondary">
					Rhymes and alphabets.
				</Typography>
			</div>

			<Grid container>
				{data.map((i) => {
					return (
						<Grid item key={i.ID} xs={12} sm={12} md={12} lg={12} className="flex-grid-item">
							<Link to={`/literacy-skill-category/${i.ID}`} className="card-link">
								<Card className="flex-grid-item__card" elevation={3}>
									<CardActionArea className="flex-grid-item__card__action-area">
										<CardContent className="flex-grid-item__card__content-area">
											<Typography gutterBottom variant="h6">
												{i.Category}
											</Typography>
											<Typography variant="body2" color="textSecondary" component="p">
												{i.Description}
											</Typography>
										</CardContent>
									</CardActionArea>
									<CardActions className="card-actions card-actions--bg-red">
										<Button>
											See all
										</Button>
									</CardActions>
								</Card>
							</Link>
						</Grid>
					)
				})}
			</Grid>
		</div>
	)
}
