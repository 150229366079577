import React, { Component } from 'react'
import axios from 'axios'

import Typography from '@material-ui/core/Typography';

import Helmet from 'react-helmet';
import VideoEmbed from '../../Layout/VideoEmbed';

class SongPage extends Component {

	state = {
		song: [],
		hasVideo: false
	}

	componentDidMount() {
		const songID = this.props.match.params.id;

		axios
			.get(`https://kiribatifederationaotearoa.co.nz/api/songs/byID/${songID}`)
			.then(res => this.setState({ song: res.data }));
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>{`Te Ribana - ${this.state.song.Title}`}</title>
				</Helmet>
				
				<Typography variant="h4" className="item-title">
					{this.state.song.Title}
				</Typography>
				<VideoEmbed videoId={this.state.song.VideoID} videoTitle={this.state.song.VideoTitle}></VideoEmbed>
				<Typography dangerouslySetInnerHTML={{ __html: this.state.song.Content }} className="content-body">
				</Typography>
			</div>
		)
	}
}

export default SongPage