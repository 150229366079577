import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Helmet from 'react-helmet';

class StoryCategoryPage extends Component {

	state = {
		storyCategory: []
	}

	componentDidMount() {
		const storyCategoryID = this.props.match.params.id;

		axios
			.get(`https://kiribatifederationaotearoa.co.nz/api/story-categories/byID/${storyCategoryID}`)
			.then(res => this.setState({ storyCategory: res.data }));
	}

	render() {
		return (
			<div className="mainWrapper">
				<Helmet>
					<title>{`Te Ribana - ${this.state.storyCategory.Category}`}</title>
				</Helmet>

				<div className="page-header">
					<Typography gutterBottom variant="h5">
						Stories from {this.state.storyCategory.Category}
					</Typography>
				</div>

				<Grid container>
					{
						this.state.storyCategory.Stories && this.state.storyCategory.Stories.length > 0
							?
							this.state.storyCategory.Stories.map((i) => {
								return (
									<Grid key={i.ID} item xs={12} sm={12} md={6} lg={4} className="flex-grid-item">
										<Link to={`/story/${i.ID}`} className="card-link">
											<Card className="flex-grid-item__card" elevation={3}>
												<CardActionArea className="flex-grid-item__card__action-area">
													<CardContent className="flex-grid-item__card__content-area">
														<Typography gutterBottom variant="h6">
															{i.Title}
														</Typography>
														<Typography variant="body2" color="textSecondary" component="p">
															{i.Summary}
														</Typography>
													</CardContent>
												</CardActionArea>
												<CardActions className="card-actions card-actions--bg-yellow">
													<Button>
														Read story
													</Button>
												</CardActions>
											</Card>
										</Link>
									</Grid>
								)
							})
							:
							"Loading..."
					}
				</Grid>
			</div>
		)
	}
}

export default StoryCategoryPage