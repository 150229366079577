import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  iframe_wrapper: {
    padding: '57.51% 0 0 0',
    position: 'relative',
    margin: '0px 0px 15px'
  },
  iframe_custom: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  }
}));

export default function VideoEmbed(props) {

  const classes = useStyles();
  const videoID = props.videoId;

  if (videoID !== null && videoID !== undefined) {
    const videoSrc = `https://player.vimeo.com/video/${videoID}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`;

    return (
      <div className={classes.iframe_wrapper}>
        <iframe
          className={classes.iframe_custom}
          src={videoSrc}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title={props.videoTitle}>
        </iframe>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
    )
  }
  
  return null;

}