import React from 'react';
import useSWR from 'swr';

import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
	card: {
		color: '#fff',
		textAlign: 'left',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between'
	},
	cardMedia: {
		minHeight: 150,
	},
	cardActions: {
		justifyContent: 'center',
		background: '#4b8fde'
	},
	cardTitle: {
		color: '#000'
	},
	gridItem: {
		display: 'flex',
		flexDirection: 'column',
		margin: '10px 0px',
		'@media only screen and (min-width: 960px)': {
			'&:nth-child(even)': {
				padding: '0px 5px'
			}
		}
	},
	gridItemLink: {
		color: '#146acc',
		textDecoration: 'none',
		width: '100%',
		height: '100%',
		textAlign: 'end'
	},
	sectionIcon: {
		color: '#f1d040',
		position: 'relative',
		top: 5,
		height: '35px',
		width: '35px',
		marginRight: '5px'
	}
}));

export default function Dashboard() {
	const classes = useStyles();

	// fetch About page data
	const BaseURL = 'https://kiribatifederationaotearoa.co.nz';
	const url = `${BaseURL}/api/dashboard/all`;
	const fetcher = (...args) => fetch(...args).then(res => res.json());
	const { data, error } = useSWR(url, fetcher);

	// error handling
	if (error) return <div className="loading-container">Failed to load</div>;
	if (!data) return <Grid container justify="center" alignItems="center" className="loading-container">
		<CircularProgress disableShrink />
	</Grid>;

	const AboutPageData = data[0][0];
	const StoriesPageData = data[0][1];
	const SongsPageData = data[0][2];
	const RhymesPageData = data[0][3];

	console.log(StoriesPageData.StoriesPageCoverImgURL);

	return (
		<Grid container>
			<Grid item xs={12} sm={12} md={6} lg={6} className={classes.gridItem}>
				<Link to="/stories" className={classes.gridItemLink}>
					<Card className={classes.card} elevation={3}>
						<CardActionArea>
							<CardMedia
								className={classes.cardMedia}
								image={BaseURL + StoriesPageData.StoriesPageCoverImgURL}
								title={StoriesPageData.StoriesPageCoverImgTitle}
							/>
							<CardContent className="flex-grid-item__card__content-area">
								<div className="card-title-wrapper">
									<Icon.BookOpen className={classes.sectionIcon} />
									<div className="card-title-wrapper-inner-right">
										<Typography variant="h5" className={classes.cardTitle}>
											{StoriesPageData.StoriesPageTitle}
										</Typography>
										<Typography variant="subtitle2" className={classes.cardTitle}>
											{StoriesPageData.StoriesPageSubTitle}
										</Typography>
									</div>
								</div>

								<Typography variant="body2" color="textSecondary" component="p">
									{StoriesPageData.StoriesPageSummary}
								</Typography>
							</CardContent>
						</CardActionArea>
						<CardActions className={classes.cardActions}>
							<Button size="small">
								{StoriesPageData.StoriesPageLinkLabel}
							</Button>
						</CardActions>
					</Card>
				</Link>
			</Grid>

			<Grid item xs={12} sm={12} md={6} lg={6} className={classes.gridItem}>
				<Link to="/songs" className={classes.gridItemLink}>
					<Card className={classes.card} elevation={3}>
						<CardActionArea>
							<CardMedia
								className={classes.cardMedia}
								image={BaseURL + SongsPageData.SongsPageCoverImgURL}
								title={SongsPageData.SongsPageCoverImgTitle}
							/>
							<CardContent className="flex-grid-item__card__content-area">
								<div className="card-title-wrapper">
									<Icon.Music className={classes.sectionIcon} />
									<div className="card-title-wrapper-inner-right">
										<Typography variant="h5" className={classes.cardTitle}>
											{SongsPageData.SongsPageTitle}
										</Typography>
										<Typography variant="subtitle2" className={classes.cardTitle}>
											{SongsPageData.SongsPageSubTitle}
										</Typography>
									</div>
								</div>

								<Typography variant="body2" color="textSecondary" component="p">
									{SongsPageData.SongsPageSummary}
								</Typography>
							</CardContent>
						</CardActionArea>
						<CardActions className={classes.cardActions}>
							<Button size="small" color="primary">
								{SongsPageData.SongsPageLinkLabel}
							</Button>
						</CardActions>
					</Card>
				</Link>
			</Grid>

			<Grid item xs={12} sm={12} md={6} lg={6} className={classes.gridItem}>
				<Link to="/literacy-skills" className={classes.gridItemLink}>
					<Card className={classes.card} elevation={3}>
						<CardActionArea>
							<CardMedia
								className={classes.cardMedia}
								image={BaseURL + RhymesPageData.RhymesPageCoverImgURL}
								title={RhymesPageData.RhymesPageCoverImgTitle}
							/>
							<CardContent className="flex-grid-item__card__content-area">
								<div className="card-title-wrapper">
									<Icon.Edit2 className={classes.sectionIcon} />
									<div className="card-title-wrapper-inner-right">
										<Typography variant="h5" className={classes.cardTitle}>
											{RhymesPageData.RhymesPageTitle}
										</Typography>
										<Typography variant="subtitle2" className={classes.cardTitle}>
											{RhymesPageData.RhymesPageSubTitle}
										</Typography>
									</div>
								</div>
								<Typography variant="body2" color="textSecondary" component="p">
									{RhymesPageData.RhymesPageSummary}
								</Typography>
							</CardContent>
						</CardActionArea>
						<CardActions className={classes.cardActions}>
							<Button size="small" color="primary">
								{RhymesPageData.RhymesPageLinkLabel}
							</Button>
						</CardActions>
					</Card>
				</Link>
			</Grid>

			<Grid item xs={12} sm={12} md={6} lg={6} className={classes.gridItem}>
				<Link to="/about" className={classes.gridItemLink}>
					<Card className={classes.card} elevation={3}>
						<CardActionArea>
							<CardMedia
								className={classes.cardMedia}
								image={BaseURL + AboutPageData.AboutPageCoverImgURL}
								title={AboutPageData.AboutPageCoverImgTitle}
							/>
							<CardContent className="flex-grid-item__card__content-area">
								<div className="card-title-wrapper">
									<Icon.Info className={classes.sectionIcon} />
									<div className="card-title-wrapper-inner-right">
										<Typography variant="h5" className={classes.cardTitle}>
											{AboutPageData.AboutPageTitle}
										</Typography>
										<Typography variant="subtitle2" className={classes.cardTitle}>
											{AboutPageData.AboutPageSubTitle}
										</Typography>
									</div>
								</div>
								<Typography variant="body2" color="textSecondary" component="p">
									{AboutPageData.AboutPageSummary}
								</Typography>
							</CardContent>
						</CardActionArea>
						<CardActions className={classes.cardActions}>
							<Button size="small" color="primary">
								{AboutPageData.AboutPageLinkLabel}
							</Button>
						</CardActions>
					</Card>
				</Link>
			</Grid>
		</Grid>
	)
}
