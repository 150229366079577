import React, { Component } from 'react'
import axios from 'axios'

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Helmet from 'react-helmet';

import VideoEmbed from '../../Layout/VideoEmbed';

class LiteracySkillPage extends Component {

	state = {
		literacySkill: []
	}

	componentDidMount() {
		const literacySkillID = this.props.match.params.id;

		axios
			.get(`https://kiribatifederationaotearoa.co.nz/api/literacy-skills/byID/${literacySkillID}`)
			.then(res => this.setState({ literacySkill: res.data }));
	}

	render() {

		return (
			<div>
				<Helmet>
					<title>{`Te Ribana - ${this.state.literacySkill.Title}`}</title>
				</Helmet>
				
				<Grid item xs={12}>
					<Typography variant="h4" className="item-title">
						{this.state.literacySkill.Title}
					</Typography>
					<VideoEmbed videoId={this.state.literacySkill.VideoID} videoTitle={this.state.literacySkill.VideoTitle}></VideoEmbed>
					<Typography dangerouslySetInnerHTML={{ __html: this.state.literacySkill.Content }} className="content-body">
					</Typography>
				</Grid>
			</div>
		)
	}
}

export default LiteracySkillPage