import React from 'react';
import Container from '@material-ui/core/Container';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import GA from './utils/GoogleAnalytics';

import { makeStyles } from '@material-ui/core/styles';

import Header from './components/Layout/Header';
import AboutPage from './components/Pages/About/AboutPage';
import Dashboard from './components/Pages/Dashboard/Dashboard';
import StoriesPage from './components/Pages/Story/StoriesPage';
import StoryCategoryPage from './components/Pages/Story/StoryCategoryPage';
import StoryPage from './components/Pages/Story/StoryPage';
import SongsPage from './components/Pages/Song/SongsPage';
import SongPage from './components/Pages/Song/SongPage';
import LiteracySkillsPage from './components/Pages/LiteracySkills/LiteracySkillsPage';
import LiteracySkillPage from './components/Pages/LiteracySkills/LiteracySkillPage';
import LiteracySkillCategoryPage from './components/Pages/LiteracySkills/LiteracySkillsCategoryPage';

import ScrollToTop from './utils/ScrollToTop';

import Helmet from 'react-helmet';

export default function App() {

	const useStyles = makeStyles(() => ({
		appInnerWrapper: {
			display: 'flex',
			flexDirection: 'column'
		}
	}));

	const classes = useStyles();

	return (
		<Router>
			<div className="app">
				<Helmet>
					<title>Te Ribana - Home</title>
				</Helmet>

				<Header />
				<Container>
					<div className={classes.appInnerWrapper}>
						{GA.init() && <GA.RouteTracker />}
						<ScrollToTop />
						<Switch>
							<Route path="/" exact component={Dashboard} />
							<Route path="/about" exact component={AboutPage} />
							<Route path="/stories" exact component={StoriesPage} />
							<Route path="/story-category/:id" exact component={StoryCategoryPage} />
							<Route path="/story/:id" exact component={StoryPage} />
							<Route path="/songs" exact component={SongsPage} />
							<Route path="/song/:id" exact component={SongPage} />
							<Route path="/literacy-skills" exact component={LiteracySkillsPage} />
							<Route path="/literacy-skill-category/:id" exact component={LiteracySkillCategoryPage} />
							<Route path="/literacy-skill/:id" exact component={LiteracySkillPage} />
						</Switch>
					</div>
				</Container>
			</div>
		</Router>
	)
}