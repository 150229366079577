import React from 'react';

import useSWR from 'swr';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import Helmet from 'react-helmet';
import { Info } from 'react-feather';

export default function AboutPage() {
  // fetch About page data
  const url = 'https://kiribatifederationaotearoa.co.nz/api/dashboard/all';
  const fetcher = (...args) => fetch(...args).then(res => res.json());
  const { data, error } = useSWR(url, fetcher);

  // error handling
  if (error) return <div className="loading-container">Failed to load</div>;
  if (!data) return <Grid container justify="center" alignItems="center" className="loading-container">
    <CircularProgress disableShrink />
  </Grid>;

  const AboutPageData = data[0][0];

  return (
    <div>
      <div className="page-header">
        <Helmet>
          <title>{AboutPageData.AboutPageDocumentTitle}</title>
        </Helmet>
        <Typography variant="h4">
          <Info /> {AboutPageData.AboutPageTitle}
        </Typography>
        <Typography gutterBottom variant="h6" className="landing-page-sub-title">
          {AboutPageData.AboutPageSubTitle}
        </Typography>
      </div>

      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} className="flex-grid-item">
          <Typography dangerouslySetInnerHTML={{ __html: AboutPageData.AboutPageContent }} className="content-body"></Typography>
        </Grid>
      </Grid>
    </div>
  )
}