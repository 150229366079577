import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Helmet from 'react-helmet';

class LiteracySkillsCategoryPage extends Component {

	state = {
		literacySkillCategory: []
	}

	componentDidMount() {
		const literacySkillCategoryID = this.props.match.params.id;

		axios
			.get(`https://kiribatifederationaotearoa.co.nz/api/literacy-skill-categories/byID/${literacySkillCategoryID}`)
			.then(res => this.setState({ literacySkillCategory: res.data }));
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>{`Te Ribana - ${this.state.literacySkillCategory.Category}`}</title>
				</Helmet>

				<div className="page-header">
					<Typography gutterBottom variant="h4">
						{this.state.literacySkillCategory.Category}
					</Typography>
					<Typography gutterBottom variant="body1" color="textSecondary">
						{this.state.literacySkillCategory.Description}
					</Typography>
				</div>

				<Grid container>
					{
						this.state.literacySkillCategory.LiteracySkills && this.state.literacySkillCategory.LiteracySkills.length > 0
							?
							this.state.literacySkillCategory.LiteracySkills.map((i) => {
								return (
									<Grid item key={i.ID} xs={12} sm={12} md={6} lg={4} className="flex-grid-item">
										<Link to={`/literacy-skill/${i.ID}`} className="card-link">
											<Card className="flex-grid-item__card" elevation={3}>
												<CardActionArea className="flex-grid-item__card__action-area">
													<CardContent className="flex-grid-item__card__content-area">
														<Typography gutterBottom variant="h5" component="h2">
															{i.Title}
														</Typography>
														<Typography variant="body2" color="textSecondary" component="p">
															{i.Summary}
														</Typography>
													</CardContent>
												</CardActionArea>
												<CardActions className="card-actions card-actions--bg-yellow">
													<Button>
														Read
													</Button>
												</CardActions>
											</Card>
										</Link>
									</Grid>
								)
							})
							:
							"Loading..."
					}
				</Grid>
			</div>
		)
	}
}

export default LiteracySkillsCategoryPage